
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { ref } from "yup";

import HistorySiswa from "@/components/1crm/siswa/history.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    HistorySiswa,
  },
  props: {
    widgetClasses: String,
  },

  beforeMount() {
    const store = useStore();
    store.dispatch(Actions.VERIFY_AUTH_SISWA);
    setCurrentPageBreadcrumbs("History Daftar Siswa ke PSP", ["Data"]);
  },
});
