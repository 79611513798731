
import DaftarPSP from "@/components/1crm/siswa/daftar-psp.vue";
import ApiService from "@/core/services/ApiService";
// end import loading overlay
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import { Field } from "vee-validate";
import { defineComponent, ref } from "vue";
// Import loading overlay
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    DaftarPSP,
    Loading, //loading overlay
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      role: JSON.parse(localStorage.getItem("user_account")!).role,
      namasekolah: "", //JSON.parse(localStorage.getItem("user_account")!).name,
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      migrasiModalRef: ref<null | HTMLElement>(null),
      disableButton: false,
      tableData: [],
      listSekolah: [],
      dateFrom: "",
      dateUntil: "",
      dateAll: "",
      schoolId: "",
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      showFilter : false,
      timer: 0,
    };
  },

  beforeMount() {
    var uu = JSON.parse(localStorage.getItem("user_account")!).role;
    if (uu == "ADMIN_SCHOOL") {
      this.schoolId = JSON.parse(
        localStorage.getItem("user_account")!
      ).schoolId;
    } else {
      this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
    }
    console.log(this.schoolId);

    this.getListSekolah();
    this.dateFrom = JSON.parse(localStorage.getItem("tanggalAwal")!);
    this.dateUntil = JSON.parse(localStorage.getItem("tanggalAkhir")!);
    this.dateAll = JSON.parse(localStorage.getItem("dateAll")!);

    this.getData(this.paging.size, this.paging.page);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      var role = JSON.parse(localStorage.getItem("user_account")!).role;
      if (role == "ADMIN_SCHOOL") {
        this.schoolId = JSON.parse(
          localStorage.getItem("user_account")!
        ).schoolId;
      } else {
        this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
      }

      if (this.schoolId || this.schoolId != null) {
        this.isLoading = true;

        const toast = useToast();
        let search1 = "";
        if (this.dateFrom) {
          search1 = "dateFrom=" + this.dateFrom + "&";
        } else {
          search1;
        }

        let search2 = "";
        if (this.dateUntil) {
          search2 = "dateUntil=" + this.dateUntil + "&";
        } else {
          search2;
        }

        ApiService.getWithoutSlug(
          "crmv2/main_invoice/invoice/office/log_process/" +
            this.schoolId +
            "?item=Migration&" +
            search1 +
            search2 +
            "&page=" +
            page +
            "&size=" +
            size +
            "&sort=name&dir=1"
        )
          .then(({ data }) => {
            this.tableData = data.content;
            this.paging.page = data.page;
            this.paging.size = data.size;
            this.paging.totalElements = data.totalElements;
            this.paging.totalPages = data.totalPages;

            this.isLoading = false;
          })
          .catch(({ response }) => {
            this.isLoading = false;
          });
      } else {
        this.tableData = [];
      }
    },
    handleBlobResponse(blobFileData, extension) {
      let school: any = this.listSekolah.find(
        (item: any) => item._id == this.schoolId
      );
      this.namasekolah = school.name || "";
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `History daftar Siswa ${this.namasekolah}.${extension}`
      ); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },
    getListSekolah() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/main_school/school/school")

        .then(({ data }) => {
          console.log(this.schoolId);
          this.listSekolah = data;
          if (this.role == "ADMIN_SCHOOL") {
            let school = data.find((item) => item._id == this.schoolId);
            this.namasekolah = school.name;
          }
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    pilihSekolah(event) {
      if (event) {
        this.schoolId = event;
        let school: any = this.listSekolah.find(
          (item: any) => item._id == this.schoolId
        );
        this.namasekolah = school.name || "";
      } else {
        this.schoolId = "";
        this.namasekolah = "";
      }
      localStorage.setItem("schoolName", JSON.stringify(this.namasekolah));
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    pilihTanggalAll() {
      this.dateFrom = this.dateAll[0];
      this.dateUntil = this.dateAll[1];

      localStorage.setItem("dateAll", JSON.stringify(this.dateAll));
      localStorage.setItem("tanggalAwal", JSON.stringify(this.dateFrom));
      localStorage.setItem("tanggalAkhir", JSON.stringify(this.dateUntil));
      this.getData(this.paging.size, this.paging.page);
    },

    detailHistory(index, item) {
      localStorage.setItem("processId", JSON.stringify(item.processId));
      localStorage.setItem("paymentId", JSON.stringify(item.paymentId));
      localStorage.setItem("schoolName", JSON.stringify(this.namasekolah));
      this.$router.push("/superadmin/siswa/history/" + item.processId);
    },

    dateTime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    downloads() {
      const toast = useToast();
      var tanggalAwal = JSON.parse(localStorage.getItem("tanggalAwal")!);
      var tanggalAkhir = JSON.parse(localStorage.getItem("tanggalAkhir")!);
      if (this.role == "ADMIN_SCHOOL") schooldId = this.schoolId;
      else var schooldId = JSON.parse(localStorage.getItem("sekolahId")!);
      if (this.schoolId) {
        this.isLoading = true;
        ApiService.getDownload(
          `crmv2/main_student/student/office/log_migration/download/history_migration.xlsx?schoolId=${schooldId}&dateFrom=${tanggalAwal}&dateUntil=${tanggalAkhir}`
        )
          .then((response) => {
            this.handleBlobResponse(response.data, "xlsx");
            toast.success("Sukses Unduh File");
            this.isLoading = false;
          })
          .catch((error) => {
            toast.error("Pilih Tanggal Terlebih Dahulu");
            this.isLoading = false;
          });
      } else {
        toast.error("Pilih Sekolah dan Tanggal Terlebih Dahulu");
      }
    },

    //event
    eventDelaySearch(e) {
      clearTimeout(this.timer) //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {
          const page = this.paging.page;
          this.getData(this.paging.size, page);
        }, 1000)
    },
    showHideFilter(){
      this.showFilter = !this.showFilter;
    },
  },
});
